import BoldIcon from '../images/BoldTextIcon.png';
import UnpaintedIcon from '../images/UnpaintedIcon.png';
import ItalicTextIcon from '../images/ItalicTextIcon.png';
import { TextAlignLeftIcon, TextAlignCenterIcon, TextAlignRightIcon } from "@shopify/polaris-icons"

export const TEXT_SETTINGS_BLOCKS = [
  {
    heading: 'Product title',
    blockType: 'productTitle',
  },
  {
    heading: 'Product subtitle',
    blockType: 'productSubtitle',
  },
  {
    heading: 'Price',
    blockType: 'productPrice',
  },
];

export const TEXT_STYLE_TYPES = [
  {
    styleName: 'alignment',
    styleTitle: 'Alignment',
  },
  {
    styleName: 'size',
    styleTitle: 'Size',
  },
  {
    styleName: 'color',
    styleTitle: 'Color',
  },
  {
    styleName: 'style',
    styleTitle: 'Style',
  }
];

export const TEXT_STYLES = {
  alignment: [
    {
      value: 'left',
      icon: TextAlignLeftIcon
    },
    {
      value: 'center',
      icon: TextAlignCenterIcon
    },
    {
      value: 'right',
      icon: TextAlignRightIcon
    },
  ],
  size: [
    'extraLarge',
    'large',
    'medium',
    'base',
    'small',
    'extraSmall',
  ],
  color: [
    {
      value: 'none',
      color: '#625A5F',
    },
    {
      value: 'success',
      color: '#76A36A',
    },
    {
      value: 'info',
      color: '#5E94CD',
    },
    {
      value: 'warning',
      color: '#F3D082',
    },
    {
      value: 'critical',
      color: '#D24E4C',
    },
    {
      value: 'accent',
      color: '#31689A',
    },
    {
      value: 'subdued',
      color: '#898688',
    },
  ],
  style: [
    {
      value: 'bold',
      icon: BoldIcon
    },
    {
      value: 'none',
      icon: UnpaintedIcon
    },
    {
      value: 'italic',
      icon: ItalicTextIcon
    },
  ]
};

export const CE_COMPONENT_PROPS = {
  text: {
    alignment: 'alignment',
    size: 'size',
    color: 'appearance',
    style: 'emphasis',
  },
  button: {
    fill: 'kind',
    appearance: 'appearance'
  }
};

export const BUTTON_STYLES = [
  {
    styleTitle: 'Color',
    styleName: 'fill',
    options: [
      {
        id: 'inherit',
        color: '#31689A',
      },
      {
        id: 'monochrome',
        color: '#000',
      },
      {
        id: 'critical',
        color: '#D24E4C',
      },
    ],
  },
  {
    styleTitle: 'Appearance',
    styleName: 'appearance',
    options: [
      {
        id: 'primary',
        name: 'appearance',
        label: 'Primary',
      },
      {
        id: 'secondary',
        name: 'appearance',
        label: 'Secondary',
      },
      {
        id: 'plain',
        name: 'appearance',
        label: 'Plain',
      },
    ],
  },
];

export const POLARIS_TEXT_APPEARANCE_TO_COLOR = {
  accent: '#3178b8',
  subdued: '#707070',
  info: '#0073ad',
  success: '#008028',
  warning: '#b67518',
  critical: '#ec2628',
};

export const POLARIS_TEXT_SIZE_TO_SIZE = {
  extraSmall: '10px',
  small: '12px',
  base: '14px',
  medium: '16px',
  large: '18px',
  extraLarge: '24px',
};

export const POLARIS_TEXT_EMPHASIS_TO_STYLE = {
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
};

export const SELECTED_STYLE_BOX_SHADOW = '0px 3px 0px 0px rgba(0,0,0,0.75)';

export const OFFER_FILTERS = ['All', 'Active', 'Draft', 'Archived'];
export const OFFER_TYPES_FILTERS = ['Checkout.liquid', 'Checkout Extension', 'Post-purchase', 'Cart Drawer', 'Cart Page', 'Cart Popup'];

export const DISCOUNT_TYPES = [
  {
    label: 'Percentage',
    value: 'percentage',
  },
  {
    label: 'Fixed amount',
    value: 'fixedAmount',
  },
];

export const LINKS = {
  shopifyFunctionsHelp: 'https://help.upsellplus.com/en/article/discounts-shopify-functions-and-shopify-scripts-yj7gwb/',
}