import React, {useMemo} from 'react';
import { LegacyStack, Text } from "@shopify/polaris";
import TextBlockItem from './textBlockItem';
import {TEXT_STYLE_TYPES} from '../../../../../../constants/constants';
import './styles.css';

const TextSettingsBlock = (props) => {
  const {state, heading, blockType} = props;

  const renderBlockItems = useMemo(() => {
    return TEXT_STYLE_TYPES.map(({styleName, styleTitle}, index) => {
      return <TextBlockItem key={index} type={styleName} targetText={blockType} title={styleTitle} {...props}/>
    })
  }, [state, blockType]);

  return (
    <LegacyStack vertical={true} spacing='tight'>
      {
        heading &&
        <Text variant="headingMd" as="h2">{heading}</Text>
      }
      {renderBlockItems}
      <hr className='Divider'/>
    </LegacyStack>
  );
};

TextSettingsBlock.propTypes = {};

export default TextSettingsBlock;