import React, {useCallback, useMemo} from 'react';
import { LegacyStack, ButtonGroup, Button, Icon, Text } from "@shopify/polaris";
import { TextFontIcon } from "@shopify/polaris-icons";
import {CE_COMPONENT_PROPS, TEXT_STYLES, SELECTED_STYLE_BOX_SHADOW} from '../../../../../../constants/constants';
import BoldIcon from '../../../../../../images/BoldTextIcon.png';
import update from 'immutability-helper';
import './styles.css';

const TextBlockItem = (props) => {
  const {
    type,
    title,
    targetText,
    state,
    setState,
  } = props;

  const handleChange = useCallback((value, styleName, targetStyles) => {
    if (state?.textStyles) {
      if (state?.textStyles[targetStyles] && state?.textStyles[targetStyles][styleName]) {
        setState(update(state, {textStyles: { [targetStyles]: {[styleName]: {$set: value}}} }));
      } else if (state?.textStyles[targetStyles]) {
        setState(update(state, {textStyles: {[targetStyles]: {$merge: {[styleName]: value}} }}));
      } else {
        setState(update(state, {textStyles: {$merge: {[targetStyles]: {[styleName]: value}} }}));
      }
    } else {
      setState(update(state, {$merge: {textStyles: {[targetStyles]: {[styleName]: value}} }}));
    }
  }, [state, setState]);

  const checkStyle = useCallback((targetStyles, styleName, value, defaultValue) => {
    if (state.textStyles
      && state.textStyles[targetStyles]
      && state.textStyles[targetStyles][styleName]) {
      return state.textStyles[targetStyles][styleName] === value;
    } else return value === defaultValue;
  }, [state]);

  const renderAlignmentButtons = useMemo(() => {
    return TEXT_STYLES['alignment'].map(({value, icon}, index) => {
      const styleName = CE_COMPONENT_PROPS.text['alignment'];
      const targetStyles = `${targetText}Styles`;

      let buttonClasses = {};
      if (checkStyle(targetStyles, styleName, value, 'center')) {
        buttonClasses.boxShadow = SELECTED_STYLE_BOX_SHADOW;
      }

      return (
        <div key={index} className='AlignmentButton' style={buttonClasses}>
          <Button onClick={() => handleChange(value, CE_COMPONENT_PROPS.text['alignment'], `${targetText}Styles`)}>
            <Icon source={icon} color="base"/>
          </Button>
        </div>
      );
    })
  }, [state]);
  const renderSizeButtons = useMemo(() => {
    return TEXT_STYLES['size'].map((value, index) => {
      const size = 20 - index * 2;

      const styleName = CE_COMPONENT_PROPS.text['size'];
      const targetStyles = `${targetText}Styles`;

      let buttonClasses = {};
      if (checkStyle(targetStyles, styleName, value, 'base')) {
        buttonClasses.boxShadow = SELECTED_STYLE_BOX_SHADOW;
      }

      return (
        <div key={index} className='SizeButton' style={buttonClasses}>
          <Button onClick={() => handleChange(value, styleName, targetStyles)}>
            <div style={{width: `${size}px`, height: `${size}px`}}>
              <Icon source={TextFontIcon}/>
            </div>
          </Button>
        </div>
      );
    });
  }, [state]);

  const renderColorButtons = useMemo(() => {
    return TEXT_STYLES['color'].map(({value, color}, index) => {
      const styleName = CE_COMPONENT_PROPS.text['color'];
      const targetStyles = `${targetText}Styles`;

      let buttonClasses = {};
      if (checkStyle(targetStyles, styleName, value, 'none')) {
        buttonClasses.boxShadow = SELECTED_STYLE_BOX_SHADOW;
      }

      return (
        <div key={index} style={{textAlign:"center"}}>
          <div className='ColorButton' style={buttonClasses}>
            <Button onClick={() => handleChange(value, styleName, targetStyles)}>
              <div style={{backgroundColor: `${color}`}} className='ColorButtonContent'/>
            </Button>
          </div>
          <span style={{display:'block'}}>{value}</span>
        </div>
      );
    })
  }, [state]);

  const renderStyleButtons = useMemo(() => {
    return TEXT_STYLES['style'].map(({value, icon}, index) => {
      const styleName = CE_COMPONENT_PROPS.text['style'];
      const targetStyles = `${targetText}Styles`;

      let buttonClasses = {};
      if (checkStyle(targetStyles, styleName, value, 'none')) {
        buttonClasses.boxShadow = SELECTED_STYLE_BOX_SHADOW;
      }

      return (
        <div key={index} className='StyleButton' style={buttonClasses}>
          <Button onClick={() => handleChange(value, CE_COMPONENT_PROPS.text['style'], `${targetText}Styles`)}>
            <img src={icon} alt={'BoldIcon'}/>
          </Button>
        </div>
      );
    })
  }, [state]);

  const renderStyles = useCallback((styleType) => {
    switch (styleType) {
      case 'alignment':
        return (
          <ButtonGroup segmented>
            {renderAlignmentButtons}
          </ButtonGroup>
        )
      case 'size':
        return (
          <ButtonGroup variant="segmented">
            {renderSizeButtons}
          </ButtonGroup>
        );
      case 'style':
        return (
          <ButtonGroup variant="segmented">
            {renderStyleButtons}
          </ButtonGroup>
        );
      case 'color':
        return (
          <ButtonGroup>
            {renderColorButtons}
          </ButtonGroup>
        )
      default:
        return null;
    }
  }, [state, type]);

  return (
    <LegacyStack vertical={true} spacing='tight'>
      <Text variant="bodyMd" as="span">{title}</Text>
      {renderStyles(type)}
    </LegacyStack>
  );
};

export default TextBlockItem;